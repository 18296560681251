import { ValidGuess } from "../types/guess";

export const validGuessesAdvanced: ValidGuess[] = [
    { german: "ETAGE", korean: "층" },
    { german: "FRECH", korean: "과감한, 버릇없다" },
    { german: "EXTRA", korean: "추가된 것" },
    { german: "KEINE", korean: "없는" },
    { german: "LIPPE", korean: "입술" },
    { german: "KÄLTE", korean: "추위" },
    { german: "RASEN", korean: "잔디, 잔디밭, 경기장" },
    { german: "STATT", korean: "...대신에 " },
    { german: "INDEM", korean: "...하고 있으려니까 " },
    { german: "FIGUR", korean: "모습" },
    { german: "NÄHEN", korean: "바느질하다" },
    { german: "SALBE", korean: "연고" },
    { german: "LISTE", korean: "리스트, 목록" },
    { german: "IDEEN", korean: "생각, 아이디어 (복수형)" },
    { german: "SIEGE", korean: "승리 (복수형)" },
    { german: "HITZE", korean: "열, 더위" },
    { german: "ZELTE", korean: "천막, 텐트 (복수형)" },
    { german: "MAGER", korean: "마른" },
    { german: "RUNDE", korean: "라운드" },
    { german: "DEINS", korean: "너의" },
    { german: "BOMBE", korean: "폭탄" },
    { german: "LÜGEN", korean: "거짓말 하다" },
    { german: "BLIND", korean: "눈이 먼" },
    { german: "PILLE", korean: "알약" },
    { german: "BEVOR", korean: "...하기 전에" },
    { german: "STROM", korean: "전기" },
    { german: "FLECK", korean: "지점" },
    { german: "SERIE", korean: "연속, 시리즈" },
    { german: "PANNE", korean: "고장" },
    { german: "PLÄNE", korean: "계획, 지도 (복수형)" },
    { german: "BERGE", korean: "산 (복수형)" },
    { german: "TEXTE", korean: "텍스트, 본문 (복수형)" },
    { german: "SPITZ", korean: "뾰족한" },
    { german: "ENKEL", korean: "손자" },
    { german: "KÖCHE", korean: "요리사, 쿡 (복수형)" },
    { german: "NAGEL", korean: "손톱, 못" },
    { german: "RESTE", korean: "나머지 (복수형)" },
    { german: "GRUND", korean: "이유" },
    { german: "ONKEL", korean: "아저씨" },
    { german: "KURSE", korean: "강좌, 강의 (복수형)" },
    { german: "FETTE", korean: "지방, 지방질 (복수형)" },
    { german: "NADEL", korean: "바늘" },
    { german: "TIERE", korean: "동물 (복수형)" },
    { german: "HUNDE", korean: "개, 강아지 (복수형)" },
    { german: "AUGEN", korean: "눈 (복수형)" },
    { german: "TEMPO", korean: "템포, 속도" },
    { german: "KNOPF", korean: "단추" },
    { german: "IDEAL", korean: "이상적인" },
    { german: "MUTIG", korean: "용기 있는" },
    { german: "WITZE", korean: "농담 (복수형)" },
    { german: "ÜBRIG", korean: "남은" },
    { german: "KÜSTE", korean: "해안" },
    { german: "SÄTZE", korean: "문장 (복수형)" },
    { german: "BÜHNE", korean: "무대" },
    { german: "BELEG", korean: "영수증" },
    { german: "HAFEN", korean: "항구" },
    { german: "LOBEN", korean: "칭찬하다" },
    { german: "FRIST", korean: "기한, 마감 시간" },
    { german: "PROFI", korean: "전문가" },
    { german: "ANGEL", korean: "낚싯대" },
    { german: "AUTOS", korean: "자동차 (복수형)" },
    { german: "BODEN", korean: "땅" },
    { german: "STURM", korean: "폭풍" },
    { german: "ZEILE", korean: "한 줄로 늘어 선" },
    { german: "DINGE", korean: "물건, 사물 (복수형)" },
    { german: "STEIL", korean: "가파른" },
    { german: "MEINS", korean: "나의" },
    { german: "STUMM", korean: "말없이" },
    { german: "WÄRME", korean: "따뜻함, 열" },
    { german: "DAFÜR", korean: "그것을 위하여" },
    { german: "ROLLE", korean: "역할" },
    { german: "ZANGE", korean: "집게, 펜치" },
    { german: "GÄSTE", korean: "손님, 방문객 (복수형)" },
    { german: "TANTE", korean: "이모, 고모, 숙모, 백모" },
    { german: "MAUER", korean: "벽, 담" },
    { german: "JAHRE", korean: "년 (복수형)" },
    { german: "TROTZ", korean: "불구하고" },
    { german: "LAGER", korean: "창고" },
    { german: "WUNDE", korean: "상처, 부상" },
    { german: "KAKAO", korean: "코코아." },
    { german: "ZIELE", korean: "목표 (복수형)" },
    { german: "SUCHT", korean: "중독" },
    { german: "BLITZ", korean: "번개" },
    { german: "SINNE", korean: "감각 (복수형)" },
    { german: "VIDEO", korean: "비디오" },
    { german: "ZONEN", korean: "지역 (복수형)" },
    { german: "KREUZ", korean: "십자가" },
    { german: "DISCO", korean: "클럽" },
    { german: "ROSEN", korean: "장미 (복수형)" },
    { german: "RINGE", korean: "반지 (복수형)" },
    { german: "BÄRTE", korean: "수염 (복수형)" },
    { german: "TRÄNE", korean: "눈물" },
    { german: "INNEN", korean: "내부에" },
    { german: "ABGAS", korean: "배기 가스" },
    { german: "ATMEN", korean: "숨을 쉬다" },
    { german: "HÜGEL", korean: "작은 산" },
    { german: "KANAL", korean: "운하, 수로" },
    { german: "HOSEN", korean: "바지 (복수형)" },
    { german: "HÄNDE", korean: "손 (복수형)" },
    { german: "DAHER", korean: "그런 이유에서" },
    { german: "LIEBE", korean: "사랑" },
    { german: "NOTEN", korean: "학점 (복수형)" },
    { german: "GENIE", korean: "천재" },
    { german: "PASTA", korean: "파스타" },
    { german: "RENTE", korean: "연금" },
    { german: "WOLLE", korean: "양모" },
    { german: "LIMIT", korean: "한계" },
    { german: "STEIN", korean: "돌" },
    { german: "MALER", korean: "도장공" },
    { german: "TESTS", korean: "테스트, 시험 (복수형)" },
    { german: "MESSE", korean: "박람회" },
    { german: "OZEAN", korean: "대양" },
    { german: "REGAL", korean: "책장" },
    { german: "KRISE", korean: "위기" },
    { german: "EILEN", korean: "서두르다" },
    { german: "HÜTTE", korean: "오두막" },
    { german: "KÖNIG", korean: "왕" },
    { german: "NEFFE", korean: "조카" },
    { german: "ERNST", korean: "심각한" },
    { german: "KABEL", korean: "케이블" },
    { german: "NASEN", korean: "코 (복수형)" },
    { german: "FOLGE", korean: "에피소드" },
    { german: "BLASS", korean: "창백한" },
    { german: "RÄUME", korean: "방 (복수형)" },
    { german: "STILE", korean: "방식 (복수형)" },
    { german: "HUPEN", korean: "경적을 울리다" },
    { german: "FÄHRE", korean: "연락선" },
    { german: "PUNKT", korean: "점, 포인트" },
    { german: "KÜSSE", korean: "키스 (복수형)" },
    { german: "LÖSEN", korean: "풀다" },
    { german: "LINIE", korean: "선" },
    { german: "KRIEG", korean: "전쟁" },
    { german: "DIEBE", korean: "도둑 (복수형)" },
    { german: "SITZE", korean: "자리, 좌석 (복수형)" },
    { german: "TEAMS", korean: "팀 (복수형)" },
    { german: "GRILL", korean: "그릴" },
    { german: "GRUSS", korean: "인사, 안부 (복수형)" },
    { german: "FLÖTE", korean: "플루트" },
    { german: "PUPPE", korean: "인형." },
    { german: "OPFER", korean: "희생" },
    { german: "TAUBE", korean: "비둘기" },
    { german: "KURVE", korean: "곡선, 호선" },
    { german: "PAARE", korean: "짝, 벌, 쌍 (복수형)" },
    { german: "SÄFTE", korean: "주스 (복수형)" },
    { german: "HEBEN", korean: "일으키다" },
    { german: "BROTE", korean: "빵 (복수형)" },
    { german: "ZWECK", korean: "목적, 목표" },
    { german: "WORTE", korean: "단어 (복수형)" },
    { german: "LAUNE", korean: "기분" },
    { german: "PROST", korean: "건배" },
    { german: "SUMME", korean: "합, 총수" },
    { german: "DUZEN", korean: "반말하다 " },
    { german: "HÖLLE", korean: "지옥" },
    { german: "TÜTEN", korean: "봉지 (복수형)" },
    { german: "TEILE", korean: "일부 (복수형)" },
    { german: "WÄNDE", korean: "벽 (복수형)" },
    { german: "BOGEN", korean: "활, 방향 전환" },
    { german: "UMZUG", korean: "이사" },
    { german: "LEDER", korean: "가죽" },
    { german: "KAMIN", korean: "굴뚝" },
    { german: "AKTIE", korean: "주식" },
    { german: "MINUS", korean: "빼기, 마이너스" },
    { german: "ALARM", korean: "알람" },
    { german: "NEBEL", korean: "안개" },
    { german: "KLIMA", korean: "기후, 분위기." },
    { german: "BÄUME", korean: "나무 (복수형)" },
    { german: "SORGE", korean: "걱정" },
    { german: "ECKEN", korean: "코너, 구석 (복수형)" },
    { german: "STOLZ", korean: "자랑스러워하는" },
    { german: "FOTOS", korean: "사진 (복수형)" },
    { german: "PRIMA", korean: "멋진, 최고" },
    { german: "BAUER", korean: "농부" },
    { german: "SOFAS", korean: "소파 (복수형)" },
    { german: "STERN", korean: "별" },
    { german: "KLICK", korean: "찰칵하는 소리" },
    { german: "ESSIG", korean: "식초" },
    { german: "MARKE", korean: "표시, 브랜드" },
    { german: "KAMPF", korean: "싸움, 시합" },
    { german: "WIESE", korean: "초원, 풀밭" },
    { german: "MEERE", korean: "바다, 대양 (복수형)" },
    { german: "VISUM", korean: "비자" },
    { german: "TÄTER", korean: "범행자, 범인" },
    { german: "LACHS", korean: "연어" },
    { german: "SZENE", korean: "장면" },
    { german: "FALLS", korean: "...의 경우에는" },
    { german: "FILME", korean: "영화 (복수형)" },
    { german: "PARKS", korean: "공원 (복수형)" },
    { german: "LEHRE", korean: "수업, 가르침" },
    { german: "ZÄHNE", korean: "이, 이빨 (복수형)" },
    { german: "TÖPFE", korean: "냄비 (복수형)" },
    { german: "SUCHE", korean: "찾기, 검색" },
    { german: "DRUCK", korean: "프린트" },
    { german: "STUFE", korean: "계단" },
    { german: "DOSIS", korean: "량, 용량" },
    { german: "SCHAL", korean: "스카프" },
    { german: "STILL", korean: "조용한" },
    { german: "IHNEN", korean: "존칭(단수 및 복수)" },
    { german: "ZUNGE", korean: "혀" },
    { german: "ROMAN", korean: "소설" },
    { german: "MAPPE", korean: "서류철" },
    { german: "TIGER", korean: "호랑이" },
    { german: "DOSEN", korean: "통 (복수형)" },
    { german: "SCHAF", korean: "양" },
    { german: "WAREN", korean: "물건, 물품 (복수형)" },
    { german: "BÜROS", korean: "사무실 (복수형)" },
    { german: "MACHT", korean: "권력" },
    { german: "DEINE", korean: "너의" },
    { german: "KERZE", korean: "초" },
    { german: "KREIS", korean: "원, 원형" },
    { german: "FUCHS", korean: "여우" },
    { german: "EMAIL", korean: "이메일" },
    { german: "PILZE", korean: "버섯 (복수형)" },
    { german: "ALBUM", korean: "앨범" },
    { german: "NETZE", korean: "그물, 네트 (복수형)" },
    { german: "TAXIS", korean: "택시 (복수형)" },
    { german: "PIANO", korean: "피아노" },
    { german: "FOLIE", korean: "포장지, 포일" },
    { german: "MENSA", korean: "카페테리아" },
    { german: "SOCKE", korean: "양말" },
    { german: "FESTE", korean: "축제, 경축 (복수형)" },
    { german: "BÄLLE", korean: "공, 볼 (복수형)" },
    { german: "LÄNGE", korean: "길이" },
    { german: "SAHNE", korean: "크림" },
    { german: "SÖHNE", korean: "아들, 자식 (복수형)" },
    { german: "REGEL", korean: "규칙" },
    { german: "TIPPS", korean: "힌트, 암시 (복수형)" },
    { german: "HUMOR", korean: "유머" },
    { german: "MENGE", korean: "다수, 다량" },
    { german: "KNAPP", korean: "겨우, 짧다" },
    { german: "DICHT", korean: "단단한" },
    { german: "MÜNZE", korean: "동전" },
    { german: "DAUER", korean: "기한, 시간" },
    { german: "DAMIT", korean: "그것과 함께, ...하기 위하여" },
    { german: "COUCH", korean: "소파" },
    { german: "DROGE", korean: "마약" },
    { german: "BABYS", korean: "어린, 아이 (복수형)" },
    { german: "HONIG", korean: "꿀" },
    { german: "TÜRME", korean: "탑 (복수형)" },
    { german: "LEDIG", korean: "미혼의" },
    { german: "STOFF", korean: "직물" },
    { german: "WERKE", korean: "활동, 일 (복수형)" },
    { german: "TÄNZE", korean: "춤 (복수형)" },
    { german: "NÖTIG", korean: "필요한" },
    { german: "MÖHRE", korean: "당근" },
    { german: "ÜBUNG", korean: "연습, 훈련" },
    { german: "STAUB", korean: "먼지" },
    { german: "FLACH", korean: "평평한" },
    { german: "DAHIN", korean: "거기로" },
    { german: "WERTE", korean: "가치, 값, 가격 (복수형)" },
    { german: "TASTE", korean: "버튼" },
    { german: "VASEN", korean: "꽃병 (복수형)" },
    { german: "VIRUS", korean: "명사, 바이러스" },
    { german: "ECKIG", korean: "모난" },
    { german: "FLÜGE", korean: "여행, 비행 (복수형)" },
    { german: "KANNE", korean: "포트" },
    { german: "WORUM", korean: "무엇의 주위에" },
    { german: "METER", korean: "미터" },
    { german: "GLATT", korean: "미끈거리는" },
    { german: "BOOTE", korean: "보트, 소형 선박" },
    { german: "DAMEN", korean: "여자 분 (복수형)" },
    { german: "DRECK", korean: "똥" },
    { german: "KISTE", korean: "상자" },
    { german: "ÄRGER", korean: "화, 짜증" },
    { german: "KRAFT", korean: "힘, 능력" },
    { german: "BRUST", korean: "가슴" },
    { german: "KOPIE", korean: "복사" },
    { german: "ERNTE", korean: "수확" },
    { german: "ZEUGE", korean: "목격자, 증인" },
];
