import { Cell } from "./Cell";
import { Translation } from "./Translation";

export const EmptyRow = () => {
    return (
        <div className="flex mb-1">
            <Cell />
            <Cell />
            <Cell />
            <Cell />
            <Cell />
            <Translation />
        </div>
    );
};
