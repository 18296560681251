import { validGuessesAdvanced } from "../../data/validGuessesAdvanced";
import { validGuessesBeginner } from "../../data/validGuessesBeginner";
import { validGuessesFluent } from "../../data/validGuessesFluent";
import { getCellState } from "../../helper/getState";
import { ValidGuess } from "../../types/guess";
import { LetterState } from "../../types/keyboard";
import { Cell } from "./Cell";
import { Translation } from "./Translation";

type CompletedRowProps = {
    guess: string;
    currentSolution: string;
    animate?: boolean;
};

export const CompletedRow = ({
    guess,
    currentSolution,
    animate = false,
}: CompletedRowProps) => {
    const splitGuess = guess.split("") as (keyof LetterState)[];

    const getTranslation = (): ValidGuess | undefined => {
        const result = [
            ...validGuessesBeginner,
            ...validGuessesAdvanced,
            ...validGuessesFluent,
        ].find((entry) => entry.german === guess);
        return result;
    };

    return (
        <div className="flex mb-1">
            {splitGuess.map((letter, i) => (
                <Cell
                    key={i}
                    n={i}
                    value={letter}
                    status={getCellState(guess, i, currentSolution)}
                    animate={animate}
                />
            ))}
            <Translation text={getTranslation()} animate={animate} />
        </div>
    );
};
