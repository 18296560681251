import { FiDelete } from "react-icons/fi";
import { getBackgroundColor } from "../../helper/getBackgroundColor";
import { KeyStatus } from "../../types/keyboard";

type KeyProps = {
    value: string;
    status?: KeyStatus;
    onClick: (value: string) => void;
};

export const Key = ({ status, value, onClick }: KeyProps) => {
    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        onClick(value);
        event.currentTarget.blur();
    };

    const getWidth = () => {
        return value === "ENTER" || value === "DELETE" ? "w-[5.3rem]" : "w-10";
    };

    return (
        <button
            className={` ${getWidth()} h-12 lg:h-14 ${getBackgroundColor(
                status
            )} rounded-md mx-[0.15rem]`}
            onClick={handleClick}
        >
            <span
                className={`text-sm lg:text-md font-bold flex justify-center items-center ${
                    status !== undefined ? "text-white" : "text-gray-800"
                }`}
            >
                {value === "ENTER" && (
                    <span className="text-md lg:text-lg">입력</span>
                )}
                {value === "DELETE" && (
                    <FiDelete className="text-xl lg:text-2xl" />
                )}
                {value !== "DELETE" && value !== "ENTER" && value}
            </span>
        </button>
    );
};
