import { CompletedRow } from "./CompletedRow";
import { CurrentRow } from "./CurrentRow";
import { EmptyRow } from "./EmptyRow";

type GridProps = {
    guesses: string[];
    currentGuess: string;
    currentSolution: string;
    animate: boolean;
};

export const Grid = ({
    guesses,
    currentGuess,
    currentSolution,
    animate,
}: GridProps) => {
    const empties =
        guesses.length < 5 ? Array.from(Array(5 - guesses.length)) : [];

    const shouldAnimate = (i: number) => animate && i === guesses.length - 1;

    return (
        <div className="flex flex-col justify-center items-center mb-2">
            {guesses.map((guess, i) => (
                <CompletedRow
                    key={i}
                    guess={guess}
                    currentSolution={currentSolution}
                    animate={shouldAnimate(i)}
                />
            ))}
            {guesses.length < 6 && <CurrentRow guess={currentGuess} />}
            {empties.map((_, i) => (
                <EmptyRow key={i} />
            ))}
        </div>
    );
};
