import { ValidGuess } from "../types/guess";

export const validGuessesFluent: ValidGuess[] = [
    { german: "DRAHT", korean: "철사" },
    { german: "GNADE", korean: "은혜" },
    { german: "BONUS", korean: "보너스" },
    { german: "FORUM", korean: "게시판" },
    { german: "EISEN", korean: "철" },
    { german: "FLORA", korean: "식물군" },
    { german: "JUBEL", korean: "환호" },
    { german: "EIMER", korean: "양동이, 들통" },
    { german: "GEIGE", korean: "바이올린" },
    { german: "PIRAT", korean: "해적" },
    { german: "WELLE", korean: "파도." },
    { german: "BIEST", korean: "야수" },
    { german: "IMMUN", korean: "면역성이 있는" },
    { german: "SPEER", korean: "번역 중 입니다." },
    { german: "CIRKA", korean: "번역 중 입니다." },
    { german: "ABZUG", korean: "번역 중 입니다." },
    { german: "EINIG", korean: "번역 중 입니다." },
    { german: "STIEL", korean: "번역 중 입니다." },
    { german: "WEISE", korean: "번역 중 입니다." },
    { german: "LATTE", korean: "번역 중 입니다." },
    { german: "DRANG", korean: "번역 중 입니다." },
    { german: "REGIE", korean: "번역 중 입니다." },
    { german: "TAUFE", korean: "번역 중 입니다." },
    { german: "LOGOS", korean: "번역 중 입니다." },
    { german: "BETON", korean: "번역 중 입니다." },
    { german: "POKAL", korean: "번역 중 입니다." },
    { german: "TABAK", korean: "번역 중 입니다." },
    { german: "ULTRA", korean: "번역 중 입니다." },
    { german: "TONNE", korean: "번역 중 입니다." },
    { german: "NOBEL", korean: "번역 중 입니다." },
    { german: "KLANG", korean: "번역 중 입니다." },
    { german: "WAISE", korean: "번역 중 입니다." },
    { german: "IMKER", korean: "번역 중 입니다." },
    { german: "FORST", korean: "번역 중 입니다." },
    { german: "KRASS", korean: "번역 중 입니다." },
    { german: "NACKT", korean: "번역 중 입니다." },
    { german: "SPECK", korean: "번역 중 입니다." },
    { german: "KERBE", korean: "번역 중 입니다." },
    { german: "SPOTT", korean: "번역 중 입니다." },
    { german: "GLANZ", korean: "번역 중 입니다." },
    { german: "ZIEGE", korean: "번역 중 입니다." },
    { german: "NIERE", korean: "번역 중 입니다." },
    { german: "EINEN", korean: "번역 중 입니다." },
    { german: "LEHNE", korean: "번역 중 입니다." },
    { german: "KRACH", korean: "번역 중 입니다." },
    { german: "GEBOT", korean: "번역 중 입니다." },
    { german: "FROST", korean: "번역 중 입니다." },
    { german: "TADEL", korean: "번역 중 입니다." },
    { german: "PUSTE", korean: "번역 중 입니다." },
    { german: "FESCH", korean: "번역 중 입니다." },
    { german: "ORDER", korean: "번역 중 입니다." },
    { german: "RUBIN", korean: "번역 중 입니다." },
    { german: "COACH", korean: "번역 중 입니다." },
    { german: "MIXEN", korean: "번역 중 입니다." },
    { german: "LINDE", korean: "번역 중 입니다." },
    { german: "ZITAT", korean: "번역 중 입니다." },
    { german: "DRAUF", korean: "번역 중 입니다." },
    { german: "TITAN", korean: "번역 중 입니다." },
    { german: "BEBEN", korean: "번역 중 입니다." },
    { german: "FRONT", korean: "번역 중 입니다." },
    { german: "LABOR", korean: "번역 중 입니다." },
    { german: "KOHLE", korean: "번역 중 입니다." },
    { german: "RITUS", korean: "번역 중 입니다." },
    { german: "SOWIE", korean: "번역 중 입니다." },
    { german: "HECHT", korean: "번역 중 입니다." },
    { german: "ZEBRA", korean: "번역 중 입니다." },
    { german: "GRIES", korean: "번역 중 입니다." },
    { german: "PFOTE", korean: "번역 중 입니다." },
    { german: "DERER", korean: "번역 중 입니다." },
    { german: "RATIO", korean: "번역 중 입니다." },
    { german: "LOCKE", korean: "번역 중 입니다." },
    { german: "ORBIT", korean: "번역 중 입니다." },
    { german: "ANTIK", korean: "번역 중 입니다." },
    { german: "ATLAS", korean: "번역 중 입니다." },
    { german: "ACKER", korean: "번역 중 입니다." },
    { german: "BLOCK", korean: "번역 중 입니다." },
    { german: "STAND", korean: "번역 중 입니다." },
    { german: "WOVON", korean: "번역 중 입니다." },
    { german: "BLANK", korean: "번역 중 입니다." },
    { german: "EITEL", korean: "번역 중 입니다." },
    { german: "HYMNE", korean: "번역 중 입니다." },
    { german: "WRACK", korean: "번역 중 입니다." },
    { german: "POKER", korean: "번역 중 입니다." },
    { german: "BANDE", korean: "번역 중 입니다." },
    { german: "PALME", korean: "번역 중 입니다." },
    { german: "SENKE", korean: "번역 중 입니다." },
    { german: "EINER", korean: "번역 중 입니다." },
    { german: "WEDER", korean: "번역 중 입니다." },
    { german: "BRAND", korean: "번역 중 입니다." },
    { german: "NETTO", korean: "번역 중 입니다." },
    { german: "WAAGE", korean: "번역 중 입니다." },
    { german: "SAUNA", korean: "번역 중 입니다." },
    { german: "MIKRO", korean: "번역 중 입니다." },
    { german: "KOSEN", korean: "번역 중 입니다." },
    { german: "DEKAN", korean: "번역 중 입니다." },
    { german: "RASSE", korean: "번역 중 입니다." },
    { german: "HAUPT", korean: "번역 중 입니다." },
    { german: "BANAL", korean: "번역 중 입니다." },
    { german: "STORY", korean: "번역 중 입니다." },
    { german: "TANNE", korean: "번역 중 입니다." },
    { german: "MODUS", korean: "번역 중 입니다." },
    { german: "ACHSE", korean: "번역 중 입니다." },
    { german: "UNRAT", korean: "번역 중 입니다." },
    { german: "MEILE", korean: "번역 중 입니다." },
    { german: "SICHT", korean: "번역 중 입니다." },
    { german: "GRUBE", korean: "번역 중 입니다." },
    { german: "MODEM", korean: "번역 중 입니다." },
    { german: "TOLLE", korean: "번역 중 입니다." },
    { german: "SCHAU", korean: "번역 중 입니다." },
    { german: "DEPOT", korean: "번역 중 입니다." },
    { german: "OBHUT", korean: "번역 중 입니다." },
    { german: "RASCH", korean: "번역 중 입니다." },
    { german: "QUARK", korean: "번역 중 입니다." },
    { german: "PATER", korean: "번역 중 입니다." },
    { german: "GREIF", korean: "번역 중 입니다." },
    { german: "SUITE", korean: "번역 중 입니다." },
    { german: "WACHS", korean: "번역 중 입니다." },
    { german: "KRUMM", korean: "번역 중 입니다." },
    { german: "ORKAN", korean: "번역 중 입니다." },
    { german: "SILBE", korean: "번역 중 입니다." },
    { german: "KODEX", korean: "번역 중 입니다." },
    { german: "QUOTE", korean: "번역 중 입니다." },
    { german: "ERBEN", korean: "번역 중 입니다." },
    { german: "KUGEL", korean: "번역 중 입니다." },
    { german: "BETEN", korean: "번역 중 입니다." },
    { german: "ZWANG", korean: "번역 중 입니다." },
    { german: "MIMIK", korean: "번역 중 입니다." },
    { german: "BACKE", korean: "번역 중 입니다." },
    { german: "ROSIG", korean: "번역 중 입니다." },
    { german: "GUNST", korean: "번역 중 입니다." },
    { german: "SANFT", korean: "번역 중 입니다." },
    { german: "EICHE", korean: "번역 중 입니다." },
    { german: "MOTIV", korean: "번역 중 입니다." },
    { german: "ZUNFT", korean: "번역 중 입니다." },
    { german: "WERFT", korean: "번역 중 입니다." },
    { german: "BEERE", korean: "번역 중 입니다." },
    { german: "SOHLE", korean: "번역 중 입니다." },
    { german: "TRACK", korean: "번역 중 입니다." },
    { german: "KANTE", korean: "번역 중 입니다." },
    { german: "LINSE", korean: "번역 중 입니다." },
    { german: "ELITE", korean: "번역 중 입니다." },
    { german: "SIPPE", korean: "번역 중 입니다." },
    { german: "HINZU", korean: "번역 중 입니다." },
    { german: "STURZ", korean: "번역 중 입니다." },
    { german: "BIENE", korean: "번역 중 입니다." },
    { german: "GURKE", korean: "번역 중 입니다." },
    { german: "ERBSE", korean: "번역 중 입니다." },
    { german: "MILDE", korean: "번역 중 입니다." },
    { german: "PHASE", korean: "번역 중 입니다." },
    { german: "LAUTE", korean: "번역 중 입니다." },
    { german: "KEGEL", korean: "번역 중 입니다." },
    { german: "TRAKT", korean: "번역 중 입니다." },
    { german: "HUMAN", korean: "번역 중 입니다." },
    { german: "LUCHS", korean: "번역 중 입니다." },
    { german: "DRAMA", korean: "번역 중 입니다." },
    { german: "ZELLE", korean: "번역 중 입니다." },
    { german: "BUCHE", korean: "번역 중 입니다." },
    { german: "KREDO", korean: "번역 중 입니다." },
    { german: "PAPST", korean: "번역 중 입니다." },
    { german: "TARIF", korean: "번역 중 입니다." },
    { german: "GRAMM", korean: "번역 중 입니다." },
    { german: "NONNE", korean: "번역 중 입니다." },
    { german: "KREBS", korean: "번역 중 입니다." },
    { german: "GREIS", korean: "번역 중 입니다." },
    { german: "LANZE", korean: "번역 중 입니다." },
    { german: "TANGO", korean: "번역 중 입니다." },
    { german: "GRIFF", korean: "번역 중 입니다." },
    { german: "BOXEN", korean: "번역 중 입니다." },
    { german: "VOTUM", korean: "번역 중 입니다." },
    { german: "TAGEN", korean: "번역 중 입니다." },
    { german: "MORAL", korean: "번역 중 입니다." },
    { german: "SERUM", korean: "번역 중 입니다." },
    { german: "EBNEN", korean: "번역 중 입니다." },
    { german: "FAUNA", korean: "번역 중 입니다." },
    { german: "RINDE", korean: "번역 중 입니다." },
    { german: "PROSA", korean: "번역 중 입니다." },
    { german: "KRANZ", korean: "번역 중 입니다." },
    { german: "KORPS", korean: "번역 중 입니다." },
    { german: "LOYAL", korean: "번역 중 입니다." },
    { german: "ORGIE", korean: "번역 중 입니다." },
    { german: "HERAN", korean: "번역 중 입니다." },
    { german: "TROLL", korean: "번역 중 입니다." },
    { german: "SOMIT", korean: "번역 중 입니다." },
    { german: "MASSE", korean: "번역 중 입니다." },
    { german: "EIFER", korean: "번역 중 입니다." },
    { german: "PFEIL", korean: "번역 중 입니다." },
    { german: "ERKER", korean: "번역 중 입니다." },
    { german: "VENUS", korean: "번역 중 입니다." },
    { german: "GASSE", korean: "번역 중 입니다." },
    { german: "MASKE", korean: "번역 중 입니다." },
    { german: "IKONE", korean: "번역 중 입니다." },
    { german: "FRACK", korean: "번역 중 입니다." },
    { german: "FATAL", korean: "번역 중 입니다." },
    { german: "BELAG", korean: "번역 중 입니다." },
    { german: "KADER", korean: "번역 중 입니다." },
    { german: "GESTE", korean: "번역 중 입니다." },
    { german: "LOSEN", korean: "번역 중 입니다." },
    { german: "DAVON", korean: "번역 중 입니다." },
    { german: "STARR", korean: "번역 중 입니다." },
    { german: "HAGER", korean: "번역 중 입니다." },
    { german: "ARTEN", korean: "번역 중 입니다." },
    { german: "PIXEL", korean: "번역 중 입니다." },
    { german: "RAUPE", korean: "번역 중 입니다." },
    { german: "LUXUS", korean: "번역 중 입니다." },
    { german: "SPURT", korean: "번역 중 입니다." },
    { german: "BRETT", korean: "번역 중 입니다." },
    { german: "HAKEN", korean: "번역 중 입니다." },
    { german: "HAUCH", korean: "번역 중 입니다." },
    { german: "SITTE", korean: "번역 중 입니다." },
    { german: "HEBEL", korean: "번역 중 입니다." },
    { german: "LABIL", korean: "번역 중 입니다." },
    { german: "THRON", korean: "번역 중 입니다." },
    { german: "HERDE", korean: "번역 중 입니다." },
    { german: "URALT", korean: "번역 중 입니다." },
    { german: "NOVUM", korean: "번역 중 입니다." },
    { german: "BASTA", korean: "번역 중 입니다." },
    { german: "BEIGE", korean: "번역 중 입니다." },
    { german: "MIENE", korean: "번역 중 입니다." },
    { german: "WANGE", korean: "번역 중 입니다." },
    { german: "ZIVIL", korean: "번역 중 입니다." },
    { german: "DARAN", korean: "번역 중 입니다." },
    { german: "ROBBE", korean: "번역 중 입니다." },
    { german: "BINDE", korean: "번역 중 입니다." },
    { german: "ZUCHT", korean: "번역 중 입니다." },
    { german: "DARIN", korean: "번역 중 입니다." },
    { german: "EMPOR", korean: "번역 중 입니다." },
    { german: "FERNE", korean: "번역 중 입니다." },
    { german: "ZWERG", korean: "번역 중 입니다." },
    { german: "HAGEL", korean: "번역 중 입니다." },
    { german: "GENOM", korean: "번역 중 입니다." },
    { german: "RASER", korean: "번역 중 입니다." },
    { german: "FUTUR", korean: "번역 중 입니다." },
    { german: "VORAN", korean: "번역 중 입니다." },
    { german: "EXAKT", korean: "번역 중 입니다." },
    { german: "ASCHE", korean: "번역 중 입니다." },
    { german: "ELFER", korean: "번역 중 입니다." },
    { german: "SHIRT", korean: "번역 중 입니다." },
    { german: "OPTIK", korean: "번역 중 입니다." },
    { german: "HARFE", korean: "번역 중 입니다." },
    { german: "HINAB", korean: "번역 중 입니다." },
    { german: "GRAPH", korean: "번역 중 입니다." },
    { german: "HECKE", korean: "번역 중 입니다." },
    { german: "FEHDE", korean: "번역 중 입니다." },
    { german: "KNUTE", korean: "번역 중 입니다." },
    { german: "KNIFF", korean: "번역 중 입니다." },
    { german: "ALLEE", korean: "번역 중 입니다." },
    { german: "BOXER", korean: "번역 중 입니다." },
    { german: "RODEL", korean: "번역 중 입니다." },
    { german: "AHNEN", korean: "번역 중 입니다." },
    { german: "LIEGE", korean: "번역 중 입니다." },
    { german: "GENRE", korean: "번역 중 입니다." },
    { german: "JAGEN", korean: "번역 중 입니다." },
    { german: "FEGEN", korean: "번역 중 입니다." },
    { german: "FOYER", korean: "번역 중 입니다." },
    { german: "PRINZ", korean: "번역 중 입니다." },
    { german: "PRALL", korean: "번역 중 입니다." },
    { german: "PLAGE", korean: "번역 중 입니다." },
    { german: "LEBER", korean: "번역 중 입니다." },
    { german: "DEMUT", korean: "번역 중 입니다." },
    { german: "RAMPE", korean: "번역 중 입니다." },
    { german: "KIPPE", korean: "번역 중 입니다." },
    { german: "SAKKO", korean: "번역 중 입니다." },
    { german: "PSALM", korean: "번역 중 입니다." },
    { german: "BUSEN", korean: "번역 중 입니다." },
    { german: "PFIFF", korean: "번역 중 입니다." },
    { german: "RUINE", korean: "번역 중 입니다." },
    { german: "ZUVOR", korean: "번역 중 입니다." },
    { german: "FLUCH", korean: "번역 중 입니다." },
    { german: "DUNST", korean: "번역 중 입니다." },
    { german: "SEIDE", korean: "번역 중 입니다." },
    { german: "PFUND", korean: "번역 중 입니다." },
    { german: "BLECH", korean: "번역 중 입니다." },
    { german: "TEICH", korean: "번역 중 입니다." },
    { german: "ARENA", korean: "번역 중 입니다." },
    { german: "RADAR", korean: "번역 중 입니다." },
    { german: "JOKER", korean: "번역 중 입니다." },
    { german: "PLANE", korean: "번역 중 입니다." },
    { german: "SUMPF", korean: "번역 중 입니다." },
    { german: "DEGEN", korean: "번역 중 입니다." },
    { german: "PFLUG", korean: "번역 중 입니다." },
    { german: "TENOR", korean: "번역 중 입니다." },
    { german: "HURRA", korean: "번역 중 입니다." },
    { german: "SOWAS", korean: "번역 중 입니다." },
    { german: "MEDIA", korean: "번역 중 입니다." },
    { german: "SCHEU", korean: "번역 중 입니다." },
    { german: "MAFIA", korean: "번역 중 입니다." },
    { german: "EIGEN", korean: "번역 중 입니다." },
    { german: "WITWE", korean: "번역 중 입니다." },
    { german: "TRIST", korean: "번역 중 입니다." },
    { german: "ARTIG", korean: "번역 중 입니다." },
    { german: "LINKE", korean: "번역 중 입니다." },
    { german: "RALLY", korean: "번역 중 입니다." },
    { german: "NELKE", korean: "번역 중 입니다." },
    { german: "ARMEE", korean: "번역 중 입니다." },
    { german: "BASAR", korean: "번역 중 입니다." },
    { german: "SPATZ", korean: "번역 중 입니다." },
    { german: "LOBBY", korean: "번역 중 입니다." },
    { german: "MULDE", korean: "번역 중 입니다." },
    { german: "JUROR", korean: "번역 중 입니다." },
    { german: "BUSCH", korean: "번역 중 입니다." },
    { german: "ALTAR", korean: "번역 중 입니다." },
    { german: "FAUST", korean: "주목" },
    { german: "GROLL", korean: "번역 중 입니다." },
    { german: "LACHE", korean: "번역 중 입니다." },
    { german: "WANNE", korean: "번역 중 입니다." },
    { german: "LESER", korean: "번역 중 입니다." },
    { german: "TYPUS", korean: "번역 중 입니다." },
    { german: "UNION", korean: "번역 중 입니다." },
    { german: "MACKE", korean: "번역 중 입니다." },
    { german: "BRAUT", korean: "번역 중 입니다." },
    { german: "FAZIT", korean: "번역 중 입니다." },
    { german: "SEELE", korean: "번역 중 입니다." },
    { german: "TEDDY", korean: "번역 중 입니다." },
    { german: "GEIER", korean: "번역 중 입니다." },
    { german: "ZUDEM", korean: "번역 중 입니다." },
    { german: "WIDER", korean: "번역 중 입니다." },
    { german: "WODKA", korean: "번역 중 입니다." },
    { german: "FEIGE", korean: "번역 중 입니다." },
    { german: "BRACH", korean: "번역 중 입니다." },
    { german: "FALTE", korean: "번역 중 입니다." },
    { german: "WOBEI", korean: "번역 중 입니다." },
    { german: "WOGEN", korean: "번역 중 입니다." },
    { german: "ABTUN", korean: "번역 중 입니다." },
    { german: "GERNE", korean: "번역 중 입니다." },
    { german: "SPION", korean: "번역 중 입니다." },
    { german: "SELIG", korean: "번역 중 입니다." },
    { german: "ANBAU", korean: "번역 중 입니다." },
    { german: "WILLE", korean: "의지" },
    { german: "ZURUF", korean: "번역 중 입니다." },
    { german: "SEGEL", korean: "번역 중 입니다." },
    { german: "SAMBA", korean: "번역 중 입니다." },
    { german: "DICKE", korean: "번역 중 입니다." },
    { german: "UNGUT", korean: "번역 중 입니다." },
    { german: "TINTE", korean: "번역 중 입니다." },
    { german: "VITAL", korean: "번역 중 입니다." },
    { german: "RATTE", korean: "번역 중 입니다." },
    { german: "RIEGE", korean: "번역 중 입니다." },
    { german: "ADLIG", korean: "번역 중 입니다." },
    { german: "KOMBI", korean: "번역 중 입니다." },
    { german: "THEKE", korean: "번역 중 입니다." },
    { german: "RUHEN", korean: "번역 중 입니다." },
    { german: "KLUFT", korean: "번역 중 입니다." },
    { german: "FROMM", korean: "번역 중 입니다." },
    { german: "PFAHL", korean: "번역 중 입니다." },
    { german: "HARKE", korean: "번역 중 입니다." },
    { german: "HERAB", korean: "번역 중 입니다." },
    { german: "KNABE", korean: "번역 중 입니다." },
    { german: "FRUST", korean: "번역 중 입니다." },
    { german: "TYPEN", korean: "번역 중 입니다." },
    { german: "PARAT", korean: "번역 중 입니다." },
    { german: "QUASI", korean: "번역 중 입니다." },
    { german: "KARAT", korean: "번역 중 입니다." },
    { german: "NAGER", korean: "번역 중 입니다." },
    { german: "VORAB", korean: "번역 중 입니다." },
    { german: "OLIVE", korean: "번역 중 입니다." },
    { german: "MATTE", korean: "번역 중 입니다." },
    { german: "HEXEN", korean: "번역 중 입니다." },
    { german: "SATAN", korean: "번역 중 입니다." },
    { german: "WARTE", korean: "번역 중 입니다." },
    { german: "RUDEL", korean: "번역 중 입니다." },
    { german: "ENORM", korean: "번역 중 입니다." },
    { german: "INNIG", korean: "번역 중 입니다." },
    { german: "GUMMI", korean: "번역 중 입니다." },
    { german: "GAREN", korean: "번역 중 입니다." },
    { german: "WENDE", korean: "번역 중 입니다." },
    { german: "KAMEL", korean: "번역 중 입니다." },
    { german: "POLKA", korean: "번역 중 입니다." },
    { german: "MACHO", korean: "번역 중 입니다." },
    { german: "REELL", korean: "번역 중 입니다." },
    { german: "KELCH", korean: "번역 중 입니다." },
    { german: "KRAUT", korean: "번역 중 입니다." },
    { german: "WEIHE", korean: "번역 중 입니다." },
    { german: "LITER", korean: "번역 중 입니다." },
    { german: "SAMEN", korean: "번역 중 입니다." },
    { german: "FONDS", korean: "번역 중 입니다." },
    { german: "BLASE", korean: "번역 중 입니다." },
    { german: "GEIST", korean: "번역 중 입니다." },
    { german: "KOMMA", korean: "번역 중 입니다." },
    { german: "SOLAR", korean: "번역 중 입니다." },
    { german: "SAITE", korean: "번역 중 입니다." },
    { german: "ALPIN", korean: "번역 중 입니다." },
    { german: "FLAIR", korean: "번역 중 입니다." },
    { german: "SKALA", korean: "번역 중 입니다." },
    { german: "MAKEL", korean: "번역 중 입니다." },
    { german: "CHAOS", korean: "번역 중 입니다." },
    { german: "ROTOR", korean: "번역 중 입니다." },
    { german: "ORGEL", korean: "번역 중 입니다." },
    { german: "LEVEL", korean: "번역 중 입니다." },
    { german: "FARCE", korean: "번역 중 입니다." },
    { german: "DARUM", korean: "번역 중 입니다." },
    { german: "RAUCH", korean: "번역 중 입니다." },
    { german: "ALIBI", korean: "번역 중 입니다." },
    { german: "GERTE", korean: "번역 중 입니다." },
    { german: "BARRE", korean: "번역 중 입니다." },
    { german: "BESEN", korean: "번역 중 입니다." },
    { german: "SALSA", korean: "번역 중 입니다." },
    { german: "LEGAL", korean: "번역 중 입니다." },
    { german: "TRANK", korean: "번역 중 입니다." },
    { german: "GATTE", korean: "번역 중 입니다." },
    { german: "MOTTO", korean: "번역 중 입니다." },
    { german: "EMSIG", korean: "번역 중 입니다." },
    { german: "DAVOR", korean: "번역 중 입니다." },
    { german: "LARVE", korean: "번역 중 입니다." },
    { german: "TOBEN", korean: "번역 중 입니다." },
    { german: "KAPPE", korean: "번역 중 입니다." },
    { german: "CELLO", korean: "번역 중 입니다." },
    { german: "PLATT", korean: "번역 중 입니다." },
    { german: "BIRKE", korean: "번역 중 입니다." },
    { german: "WEBER", korean: "번역 중 입니다." },
    { german: "FIDEL", korean: "번역 중 입니다." },
    { german: "INDIZ", korean: "번역 중 입니다." },
    { german: "BARDE", korean: "번역 중 입니다." },
    { german: "MULTI", korean: "번역 중 입니다." },
    { german: "KELLE", korean: "번역 중 입니다." },
    { german: "ZIRKA", korean: "번역 중 입니다." },
    { german: "PEGEL", korean: "번역 중 입니다." },
    { german: "BANGE", korean: "번역 중 입니다." },
    { german: "KNIEN", korean: "번역 중 입니다." },
    { german: "PANIK", korean: "번역 중 입니다." },
    { german: "POSSE", korean: "번역 중 입니다." },
    { german: "RACHE", korean: "번역 중 입니다." },
    { german: "BIBER", korean: "번역 중 입니다." },
    { german: "UMBAU", korean: "번역 중 입니다." },
    { german: "SENAT", korean: "번역 중 입니다." },
    { german: "EINST", korean: "번역 중 입니다." },
    { german: "TREFF", korean: "번역 중 입니다." },
    { german: "WORAN", korean: "번역 중 입니다." },
    { german: "STAHL", korean: "번역 중 입니다." },
    { german: "VOKAL", korean: "번역 중 입니다." },
    { german: "FALKE", korean: "번역 중 입니다." },
    { german: "BUCHT", korean: "번역 중 입니다." },
    { german: "GEBET", korean: "번역 중 입니다." },
    { german: "SPALT", korean: "번역 중 입니다." },
    { german: "RUGBY", korean: "번역 중 입니다." },
    { german: "BUCHS", korean: "번역 중 입니다." },
    { german: "ENGEL", korean: "번역 중 입니다." },
    { german: "SALON", korean: "번역 중 입니다." },
    { german: "WIEGE", korean: "번역 중 입니다." },
    { german: "ENZYM", korean: "번역 중 입니다." },
    { german: "HETZE", korean: "번역 중 입니다." },
    { german: "NOTAR", korean: "번역 중 입니다." },
    { german: "WEHEN", korean: "번역 중 입니다." },
    { german: "RUMPF", korean: "번역 중 입니다." },
    { german: "ORGAN", korean: "번역 중 입니다." },
    { german: "DOLCH", korean: "번역 중 입니다." },
    { german: "PISTE", korean: "번역 중 입니다." },
    { german: "TRICK", korean: "번역 중 입니다." },
    { german: "ESCHE", korean: "번역 중 입니다." },
    { german: "MEIST", korean: "번역 중 입니다." },
    { german: "MOBIL", korean: "번역 중 입니다." },
    { german: "HEIDE", korean: "번역 중 입니다." },
    { german: "STIER", korean: "번역 중 입니다." },
    { german: "TRUPP", korean: "번역 중 입니다." },
    { german: "RODEN", korean: "번역 중 입니다." },
    { german: "WESPE", korean: "번역 중 입니다." },
    { german: "MAGIE", korean: "번역 중 입니다." },
    { german: "MANKO", korean: "번역 중 입니다." },
    { german: "TIEFE", korean: "번역 중 입니다." },
    { german: "BEUTE", korean: "번역 중 입니다." },
    { german: "GRELL", korean: "번역 중 입니다." },
    { german: "FOKUS", korean: "번역 중 입니다." },
    { german: "ZWIST", korean: "번역 중 입니다." },
    { german: "JUWEL", korean: "번역 중 입니다." },
    { german: "ERSTE", korean: "번역 중 입니다." },
    { german: "STETS", korean: "번역 중 입니다." },
    { german: "ERPEL", korean: "번역 중 입니다." },
    { german: "WEILE", korean: "번역 중 입니다." },
    { german: "SALTO", korean: "번역 중 입니다." },
    { german: "WETTE", korean: "번역 중 입니다." },
    { german: "KEULE", korean: "번역 중 입니다." },
    { german: "FINNE", korean: "번역 중 입니다." },
    { german: "CHILI", korean: "번역 중 입니다." },
    { german: "PILOT", korean: "번역 중 입니다." },
    { german: "ETHIK", korean: "번역 중 입니다." },
    { german: "TUMOR", korean: "번역 중 입니다." },
    { german: "AROMA", korean: "번역 중 입니다." },
    { german: "UNSER", korean: "번역 중 입니다." },
    { german: "LUNGE", korean: "번역 중 입니다." },
    { german: "LILIE", korean: "번역 중 입니다." },
    { german: "PAPPE", korean: "번역 중 입니다." },
    { german: "AGENT", korean: "번역 중 입니다." },
    { german: "BRAVO", korean: "번역 중 입니다." },
    { german: "PEDAL", korean: "번역 중 입니다." },
    { german: "EISIG", korean: "번역 중 입니다." },
    { german: "GALLE", korean: "번역 중 입니다." },
    { german: "FEDER", korean: "번역 중 입니다." },
    { german: "WEIDE", korean: "번역 중 입니다." },
    { german: "ALIAS", korean: "번역 중 입니다." },
    { german: "WINDE", korean: "번역 중 입니다." },
    { german: "TULPE", korean: "번역 중 입니다." },
    { german: "ROUTE", korean: "번역 중 입니다." },
    { german: "LEERE", korean: "번역 중 입니다." },
    { german: "FELGE", korean: "번역 중 입니다." },
    { german: "ORDEN", korean: "번역 중 입니다." },
    { german: "KLIPP", korean: "번역 중 입니다." },
    { german: "FAHNE", korean: "번역 중 입니다." },
    { german: "MOTTE", korean: "번역 중 입니다." },
    { german: "AZUBI", korean: "번역 중 입니다." },
    { german: "PACHT", korean: "번역 중 입니다." },
    { german: "CHROM", korean: "번역 중 입니다." },
    { german: "VORNE", korean: "번역 중 입니다." },
    { german: "FALLE", korean: "번역 중 입니다." },
    { german: "SORTE", korean: "번역 중 입니다." },
    { german: "LOGIK", korean: "번역 중 입니다." },
    { german: "KATER", korean: "번역 중 입니다." },
    { german: "WORIN", korean: "번역 중 입니다." },
    { german: "LEHEN", korean: "번역 중 입니다." },
    { german: "BRITE", korean: "번역 중 입니다." },
    { german: "FAMOS", korean: "번역 중 입니다." },
    { german: "AREAL", korean: "번역 중 입니다." },
    { german: "ABRUF", korean: "번역 중 입니다." },
    { german: "NAGEN", korean: "번역 중 입니다." },
    { german: "KRIPO", korean: "번역 중 입니다." },
    { german: "GILDE", korean: "번역 중 입니다." },
    { german: "DEKOR", korean: "번역 중 입니다." },
    { german: "UMHER", korean: "번역 중 입니다." },
    { german: "STOPP", korean: "번역 중 입니다." },
    { german: "NARBE", korean: "번역 중 입니다." },
    { german: "ZECKE", korean: "번역 중 입니다." },
    { german: "UMWEG", korean: "번역 중 입니다." },
    { german: "FADEN", korean: "번역 중 입니다." },
    { german: "FINAL", korean: "번역 중 입니다." },
    { german: "MODUL", korean: "번역 중 입니다." },
    { german: "WESTE", korean: "번역 중 입니다." },
    { german: "STAAT", korean: "번역 중 입니다." },
    { german: "OBERE", korean: "번역 중 입니다." },
    { german: "ERDEN", korean: "번역 중 입니다." },
    { german: "FEIND", korean: "번역 중 입니다." },
    { german: "ABTEI", korean: "번역 중 입니다." },
    { german: "FLUGS", korean: "번역 중 입니다." },
    { german: "HERUM", korean: "번역 중 입니다." },
    { german: "HAUBE", korean: "번역 중 입니다." },
    { german: "BARON", korean: "번역 중 입니다." },
    { german: "PROMI", korean: "번역 중 입니다." },
    { german: "LAUBE", korean: "번역 중 입니다." },
    { german: "TRITT", korean: "번역 중 입니다." },
    { german: "SCHUB", korean: "번역 중 입니다." },
    { german: "ARMUT", korean: "번역 중 입니다." },
    { german: "RIPPE", korean: "번역 중 입니다." },
    { german: "FERSE", korean: "번역 중 입니다." },
    { german: "KLAGE", korean: "번역 중 입니다." },
    { german: "STUTE", korean: "번역 중 입니다." },
    { german: "KARRE", korean: "번역 중 입니다." },
    { german: "PFAND", korean: "번역 중 입니다." },
    { german: "ANKER", korean: "번역 중 입니다." },
    { german: "WOMIT", korean: "번역 중 입니다." },
    { german: "MILIZ", korean: "번역 중 입니다." },
    { german: "KRAUS", korean: "번역 중 입니다." },
    { german: "THESE", korean: "번역 중 입니다." },
    { german: "STALL", korean: "번역 중 입니다." },
    { german: "RINNE", korean: "번역 중 입니다." },
    { german: "LEINE", korean: "번역 중 입니다." },
    { german: "MUMIE", korean: "번역 중 입니다." },
    { german: "PROBE", korean: "번역 중 입니다." },
    { german: "PLUMP", korean: "번역 중 입니다." },
    { german: "LOTSE", korean: "번역 중 입니다." },
    { german: "WAFFE", korean: "번역 중 입니다." },
    { german: "RUDER", korean: "번역 중 입니다." },
    { german: "ELEND", korean: "번역 중 입니다." },
    { german: "URBAN", korean: "번역 중 입니다." },
    { german: "ZUZUG", korean: "번역 중 입니다." },
    { german: "KLOTZ", korean: "번역 중 입니다." },
    { german: "POWER", korean: "번역 중 입니다." },
    { german: "KOMIK", korean: "번역 중 입니다." },
    { german: "TRAGE", korean: "번역 중 입니다." },
    { german: "ZWEIG", korean: "번역 중 입니다." },
    { german: "WESEN", korean: "번역 중 입니다." },
    { german: "QUALM", korean: "번역 중 입니다." },
    { german: "STUBE", korean: "번역 중 입니다." },
    { german: "DEICH", korean: "번역 중 입니다." },
    { german: "INTIM", korean: "번역 중 입니다." },
    { german: "KLAMM", korean: "번역 중 입니다." },
    { german: "FLINK", korean: "번역 중 입니다." },
    { german: "SEGEN", korean: "번역 중 입니다." },
    { german: "WILDE", korean: "번역 중 입니다." },
    { german: "KNALL", korean: "번역 중 입니다." },
    { german: "FIXEN", korean: "번역 중 입니다." },
    { german: "PUMPE", korean: "번역 중 입니다." },
    { german: "SONDE", korean: "번역 중 입니다." },
    { german: "STAMM", korean: "번역 중 입니다." },
    { german: "ABBAU", korean: "번역 중 입니다." },
    { german: "ANMUT", korean: "번역 중 입니다." },
    { german: "BORKE", korean: "번역 중 입니다." },
    { german: "FLOTT", korean: "번역 중 입니다." },
    { german: "GRUFT", korean: "번역 중 입니다." },
    { german: "LABEL", korean: "번역 중 입니다." },
    { german: "ROYAL", korean: "번역 중 입니다." },
    { german: "GLIED", korean: "번역 중 입니다." },
    { german: "HORDE", korean: "번역 중 입니다." },
    { german: "EKLAT", korean: "번역 중 입니다." },
    { german: "ACRYL", korean: "번역 중 입니다." },
    { german: "HENNE", korean: "번역 중 입니다." },
    { german: "KRONE", korean: "번역 중 입니다." },
    { german: "ANTUN", korean: "번역 중 입니다." },
    { german: "TURBO", korean: "번역 중 입니다." },
    { german: "RIESE", korean: "번역 중 입니다." },
    { german: "ATOLL", korean: "번역 중 입니다." },
    { german: "BASIS", korean: "번역 중 입니다." },
    { german: "ESSAY", korean: "번역 중 입니다." },
    { german: "SEHNE", korean: "번역 중 입니다." },
    { german: "STIRN", korean: "번역 중 입니다." },
    { german: "DELTA", korean: "번역 중 입니다." },
    { german: "WUCHT", korean: "번역 중 입니다." },
    { german: "STICH", korean: "번역 중 입니다." },
    { german: "SCHAM", korean: "번역 중 입니다." },
    { german: "STROH", korean: "번역 중 입니다." },
    { german: "ZWEIT", korean: "번역 중 입니다." },
    { german: "HAUFE", korean: "번역 중 입니다." },
    { german: "PAUKE", korean: "번역 중 입니다." },
    { german: "UNFUG", korean: "번역 중 입니다." },
    { german: "WEITE", korean: "번역 중 입니다." },
    { german: "FUNKE", korean: "번역 중 입니다." },
    { german: "EBENE", korean: "번역 중 입니다." },
    { german: "MOPED", korean: "번역 중 입니다." },
    { german: "BEZUG", korean: "번역 중 입니다." },
    { german: "AHORN", korean: "번역 중 입니다." },
    { german: "SEKTE", korean: "번역 중 입니다." },
    { german: "WELPE", korean: "번역 중 입니다." },
    { german: "ZUTAT", korean: "번역 중 입니다." },
    { german: "FABEL", korean: "번역 중 입니다." },
    { german: "STEPP", korean: "번역 중 입니다." },
    { german: "UNMUT", korean: "번역 중 입니다." },
    { german: "MODER", korean: "번역 중 입니다." },
    { german: "REIFE", korean: "번역 중 입니다." },
    { german: "TEILS", korean: "번역 중 입니다." },
    { german: "DELLE", korean: "번역 중 입니다." },
    { german: "DRITT", korean: "번역 중 입니다." },
    { german: "GOTIK", korean: "번역 중 입니다." },
    { german: "PORTO", korean: "번역 중 입니다." },
    { german: "CLOWN", korean: "번역 중 입니다." },
    { german: "OCHSE", korean: "번역 중 입니다." },
    { german: "FASER", korean: "번역 중 입니다." },
    { german: "EHREN", korean: "번역 중 입니다." },
    { german: "BIBEL", korean: "번역 중 입니다." },
    { german: "VILLA", korean: "번역 중 입니다." },
    { german: "KNAST", korean: "번역 중 입니다." },
    { german: "PRISE", korean: "번역 중 입니다." },
    { german: "KOMET", korean: "번역 중 입니다." },
    { german: "IRREN", korean: "번역 중 입니다." },
    { german: "WACHE", korean: "번역 중 입니다." },
    { german: "WEBEN", korean: "번역 중 입니다." },
    { german: "FAHRT", korean: "번역 중 입니다." },
    { german: "TROST", korean: "번역 중 입니다." },
    { german: "KANON", korean: "번역 중 입니다." },
    { german: "DAMPF", korean: "번역 중 입니다." },
    { german: "INDEX", korean: "번역 중 입니다." },
    { german: "BRUCH", korean: "번역 중 입니다." },
    { german: "CHAOT", korean: "번역 중 입니다." },
    { german: "LASER", korean: "번역 중 입니다." },
    { german: "STEIF", korean: "번역 중 입니다." },
    { german: "ORTEN", korean: "번역 중 입니다." },
    { german: "REMIS", korean: "번역 중 입니다." },
    { german: "DOGMA", korean: "번역 중 입니다." },
    { german: "ADLER", korean: "번역 중 입니다." },
    { german: "ZECHE", korean: "번역 중 입니다." },
    { german: "PASTE", korean: "번역 중 입니다." },
    { german: "SIRUP", korean: "번역 중 입니다." },
    { german: "WAGON", korean: "번역 중 입니다." },
    { german: "OHREN", korean: "번역 중 입니다." },
    { german: "BÄREN", korean: "번역 중 입니다." },
    { german: "DATEN", korean: "번역 중 입니다." },
    { german: "GUTER", korean: "번역 중 입니다." },
    { german: "GUTEN", korean: "번역 중 입니다." },
    { german: "GUTES", korean: "번역 중 입니다." },
];
