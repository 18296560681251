import { FiInfo } from "react-icons/fi";
import { ValidGuess } from "../../types/guess";
import { useSpring, animated } from "react-spring";
import { toast } from "react-toastify";
import { modalBaseStyles } from "../../styles/modalStyles";

type TranslationProps = {
    text?: ValidGuess;
    animate?: boolean;
};

export const Translation = ({ text, animate = false }: TranslationProps) => {
    const fadeIn = useSpring({
        from: { color: "#E5E7EB" },
        to: { color: "#60A5FA" },
        delay: 1800,
    });

    const onClick = () => {
        toast(
            <div className="">
                <div className="mb-4">
                    <p className="text-lg font-bold text-gray-500 mb-2 text-center">
                        {text?.german}
                    </p>
                    <p className="text-lg font-bold text-gray-800 text-center">
                        {text?.korean}
                    </p>
                </div>
                <div className="w-full flex items-center justify-center">
                    <a
                        href={`https://dict.naver.com/dekodict/#/search?query=${text?.german}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <button className="text-sm font-bold text-green-500">
                            사전
                        </button>
                    </a>
                </div>
            </div>,
            modalBaseStyles
        );
    };

    if (!text)
        return (
            <div className={`ml-4 w-8 flex justify-center items-center`}>
                <FiInfo className="text-2xl text-gray-200" />
            </div>
        );

    if (!animate)
        return (
            <button
                className="ml-4 w-8 flex items-center justify-center text-center"
                onClick={onClick}
            >
                <FiInfo className="text-2xl text-blue-400" />
            </button>
        );

    return (
        <animated.button
            style={fadeIn}
            onClick={onClick}
            className="ml-4 w-8 flex items-center justify-center text-center"
        >
            <FiInfo className="text-2xl" />
        </animated.button>
    );
};
