import { Fragment } from "react";
import { LetterState } from "../../types/keyboard";
import { Key } from "./Key";

type Props = {
    onKey: (value: string) => void;
    onDelete: () => void;
    onEnter: () => void;
    keyboardState: LetterState;
};

export const Keyboard = ({
    onKey,
    onDelete,
    onEnter,
    keyboardState,
}: Props) => {
    const onClick = (value: any) => {
        if (value === "ENTER") {
            onEnter();
        } else if (value === "DELETE") {
            onDelete();
        } else {
            onKey(value);
        }
    };

    return (
        <Fragment>
            <div className="flex justify-center mb-2">
                <Key value="Q" onClick={onClick} status={keyboardState["Q"]} />
                <Key value="W" onClick={onClick} status={keyboardState["W"]} />
                <Key value="E" onClick={onClick} status={keyboardState["E"]} />
                <Key value="R" onClick={onClick} status={keyboardState["R"]} />
                <Key value="T" onClick={onClick} status={keyboardState["T"]} />
                <Key value="Z" onClick={onClick} status={keyboardState["Z"]} />
                <Key value="U" onClick={onClick} status={keyboardState["U"]} />
                <Key value="I" onClick={onClick} status={keyboardState["I"]} />
                <Key value="O" onClick={onClick} status={keyboardState["O"]} />
                <Key value="P" onClick={onClick} status={keyboardState["P"]} />
                <Key value="Ü" onClick={onClick} status={keyboardState["Ü"]} />
            </div>
            <div className="flex justify-center mb-2">
                <Key value="A" onClick={onClick} status={keyboardState["A"]} />
                <Key value="S" onClick={onClick} status={keyboardState["S"]} />
                <Key value="D" onClick={onClick} status={keyboardState["D"]} />
                <Key value="F" onClick={onClick} status={keyboardState["F"]} />
                <Key value="G" onClick={onClick} status={keyboardState["G"]} />
                <Key value="H" onClick={onClick} status={keyboardState["H"]} />
                <Key value="J" onClick={onClick} status={keyboardState["J"]} />
                <Key value="K" onClick={onClick} status={keyboardState["K"]} />
                <Key value="L" onClick={onClick} status={keyboardState["L"]} />
                <Key value="Ö" onClick={onClick} status={keyboardState["Ö"]} />
                <Key value="Ä" onClick={onClick} status={keyboardState["Ä"]} />
            </div>
            <div className="flex justify-center mb-4">
                <Key value="ENTER" onClick={onClick}></Key>
                <Key value="Y" onClick={onClick} status={keyboardState["Y"]} />
                <Key value="X" onClick={onClick} status={keyboardState["X"]} />
                <Key value="C" onClick={onClick} status={keyboardState["C"]} />
                <Key value="V" onClick={onClick} status={keyboardState["V"]} />
                <Key value="B" onClick={onClick} status={keyboardState["B"]} />
                <Key value="N" onClick={onClick} status={keyboardState["N"]} />
                <Key value="M" onClick={onClick} status={keyboardState["M"]} />
                <Key value="DELETE" onClick={onClick}></Key>
            </div>
        </Fragment>
    );
};
