import { FiInfo } from "react-icons/fi";
import logo from "../../images/icon.png";
import { Level } from "../../types/level";
import { InfoModal } from "./InfoModal";
import { Select } from "./Select";
import { toast } from "react-toastify";
import { modalBaseStyles } from "../../styles/modalStyles";

type HeaderProps = {
    currentLevel: Level;
    onClick: (value: Level) => void;
};

export const Header = ({ currentLevel, onClick }: HeaderProps) => {
    const onInfo = () => {
        toast(<InfoModal />, {
            ...modalBaseStyles,
            autoClose: 15000,
        });
    };
    return (
        <div className="sm:w-2/3 lg:w-1/2 xl:w-1/3 flex justify-between items-center mt-2 lg:mt-4 pb-2 lg:pb-3 mx-3 sm:mx-auto border-b border-gray-400">
            <button
                onClick={onInfo}
                className="w-16 lg:w-20 flex items-center justify-start text-center"
            >
                <FiInfo className="text-2xl text-blue-400" />
            </button>
            <div className="flex items-center">
                <img
                    src={logo}
                    alt="Logo"
                    className="h-6 w-6 lg:h-8 lg:w-8 mr-1"
                />
                <span className="text-base font-bold text-slate-700">
                    KAKADUDLE
                </span>
            </div>
            <Select currentLevel={currentLevel} onClick={onClick} />
        </div>
    );
};
