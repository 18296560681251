import { Level } from "../types/level";

type StoredGameState = {
    guesses: string[];
    solution: string;
    status: "LOST" | "WON" | "NOT_DONE";
};

export const saveGameStateToLocalStorage = (
    gameState: StoredGameState,
    level: Level
) => {
    localStorage.setItem(level, JSON.stringify(gameState));
};

export const loadGameStateFromLocalStorage = (level: Level) => {
    const state = localStorage.getItem(level);
    return state ? (JSON.parse(state) as StoredGameState) : null;
};

export const saveLevelToLocalStorage = (level: Level) => {
    localStorage.setItem("level", JSON.stringify(level));
};

export const loadLevelFromLocalStorage = () => {
    const state = localStorage.getItem("level");
    return state ? (JSON.parse(state) as Level) : null;
};
