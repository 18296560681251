import { validGuessesAdvanced } from "../data/validGuessesAdvanced";
import { validGuessesBeginner } from "../data/validGuessesBeginner";
import { Level } from "../types/level";

export const getSolutionOfDay = (level: Level): string => {
    const epochMs = new Date("January 1, 2022 00:00:00").valueOf();
    const now = Date.now();
    const msInDay = 86400000;
    const index = Math.floor((now - epochMs) / msInDay);
    switch (level) {
        case "BEGINNER":
            return validGuessesBeginner[index % validGuessesBeginner.length]
                .german;
        case "ADVANCED":
            return validGuessesAdvanced[index % validGuessesAdvanced.length]
                .german;
        default:
            return validGuessesBeginner[index % validGuessesBeginner.length]
                .german;
    }
};
