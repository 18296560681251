import { validGuessesAdvanced } from "../data/validGuessesAdvanced";
import { validGuessesBeginner } from "../data/validGuessesBeginner";
import { validGuessesFluent } from "../data/validGuessesFluent";
import { ValidGuess } from "../types/guess";

export const getValidGuesses = (): ValidGuess[] => {
    return [
        ...validGuessesBeginner,
        ...validGuessesAdvanced,
        ...validGuessesFluent,
    ];
};
