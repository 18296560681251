import { Cell } from "./Cell";
import { Translation } from "./Translation";

type CurrentRowProps = {
    guess: string;
};

export const CurrentRow = ({ guess }: CurrentRowProps) => {
    const splitGuess = guess.split("");
    const emptyCells = Array.from(Array(5 - splitGuess.length));

    return (
        <div className="flex mb-1">
            {splitGuess.map((letter, i) => (
                <Cell key={i} value={letter} />
            ))}
            {emptyCells.map((_, i) => (
                <Cell key={i} />
            ))}
            <Translation />
        </div>
    );
};
