import React, { useEffect, useState } from "react";
import { Grid } from "./components/grid/Grid";
import { Keyboard } from "./components/keyboard/Board";
import { toast } from "react-toastify";
import {
    loadGameStateFromLocalStorage,
    loadLevelFromLocalStorage,
    saveGameStateToLocalStorage,
    saveLevelToLocalStorage,
} from "./store/localStorage";
import { Level } from "./types/level";
import { InfoBar } from "./components/info/InfoBar";
import { getSolutionOfDay } from "./helper/getSolutionOfDay";
import { Header } from "./components/header/Header";
import { getValidGuesses } from "./helper/getValidGuesses";
import { getKeyboardState } from "./helper/getState";
import { InfoModal } from "./components/header/InfoModal";
import { endCongratulations, endSorry, notInList } from "./data/texts";
import { LetterState } from "./types/keyboard";
import { modalBaseStyles } from "./styles/modalStyles";

function App() {
    const [guesses, setGuesses] = useState<string[]>([]);
    const [currentGuess, setCurrentGuess] = useState("");
    const [currentLevel, setCurrentLevel] = useState<Level>("BEGINNER");
    const [currentStatus, setCurrentStatus] = useState("NOT_DONE");
    const currentSolution = getSolutionOfDay(currentLevel);
    const [animate, setAnimate] = useState(false);
    const [keyboardState, setKeyboardState] = useState(
        getKeyboardState(guesses, currentSolution)
    );

    const loadInitialStore = () => {
        const level: Level[] = ["BEGINNER", "ADVANCED"];
        level.forEach((lv: Level) => {
            const loaded = loadGameStateFromLocalStorage(lv);
            const solution = getSolutionOfDay(lv);
            if (loaded?.solution !== solution) {
                setGuesses([]);
                setKeyboardState({} as LetterState);
                saveGameStateToLocalStorage(
                    {
                        guesses: [],
                        solution: solution,
                        status: "NOT_DONE",
                    },
                    lv
                );
            }
            const gameWasWon = loaded?.guesses.includes(solution);
            if (gameWasWon) {
                setCurrentStatus("WON");
            }
            if (loaded?.guesses.length === 6 && !gameWasWon) {
                setCurrentStatus("LOST");
            }
            return loaded?.guesses;
        });
    };

    const loadInitialState = () => {
        const loadedLevel = loadLevelFromLocalStorage();
        if (loadedLevel !== null) {
            setCurrentLevel(loadedLevel);
            const currentGameState = loadGameStateFromLocalStorage(loadedLevel);
            if (currentGameState !== null) {
                setCurrentStatus(currentGameState.status);
                setGuesses(currentGameState.guesses);
                setKeyboardState(
                    getKeyboardState(
                        currentGameState.guesses,
                        currentGameState.solution
                    )
                );
            }
        } else {
            saveLevelToLocalStorage(currentLevel);
            toast(<InfoModal />, {
                ...modalBaseStyles,
                autoClose: 15000,
            });
        }
    };

    useEffect(() => {
        loadInitialStore();
        loadInitialState();
    }, []);

    const onKey = (key: string) => {
        if (currentGuess.length < 5) {
            setCurrentGuess(currentGuess + key);
        }
    };

    const onDelete = () => {
        if (currentGuess.length > 0) {
            setCurrentGuess(currentGuess.slice(0, currentGuess.length - 1));
        }
    };

    const guessIsValid = (guess: string): boolean => {
        const result = getValidGuesses().find(
            (entry) => entry.german === guess
        );
        return result ? true : false;
    };

    const onLost = (guesses: string[]) => {
        setTimeout(() => {
            toast(endSorry, {
                ...modalBaseStyles,
                className: "text-center text-xl",
            });
            setCurrentStatus("LOST");
        }, 2500);
        saveGameStateToLocalStorage(
            {
                guesses: guesses,
                solution: currentSolution,
                status: "LOST",
            },
            currentLevel
        );
    };

    const onWon = () => {
        setTimeout(() => {
            toast(endCongratulations, {
                ...modalBaseStyles,
                className: "text-center text-xl",
            });
            setCurrentStatus("WON");
        }, 2500);
        const newGuesses = [...guesses, currentGuess];
        setGuesses([...guesses, currentGuess]);
        setCurrentGuess("");
        saveGameStateToLocalStorage(
            {
                guesses: newGuesses,
                solution: currentSolution,
                status: "WON",
            },
            currentLevel
        );
    };

    const onNext = (guesses: string[]) => {
        setCurrentGuess("");
        saveGameStateToLocalStorage(
            {
                guesses: guesses,
                solution: currentSolution,
                status: "NOT_DONE",
            },
            currentLevel
        );
    };

    const onEnter = () => {
        if (currentGuess.length === 5) {
            setAnimate(true);
            setTimeout(() => {
                setAnimate(false);
            }, 2500);
            if (currentGuess === currentSolution) {
                onWon();
            } else if (guessIsValid(currentGuess)) {
                const newGuesses = [...guesses, currentGuess];
                setGuesses(newGuesses);
                setCurrentGuess("");
                setTimeout(() => {
                    setKeyboardState(
                        getKeyboardState(newGuesses, currentSolution)
                    );
                }, 2000);
                if (newGuesses.length === 6) {
                    onLost(newGuesses);
                } else {
                    onNext(newGuesses);
                }
            } else {
                toast.error(notInList, {
                    ...modalBaseStyles,
                    className: "text-center text-base",
                });
            }
        }
    };

    const onSelectLevel = (level: Level) => {
        saveLevelToLocalStorage(level);
        setCurrentLevel(level);
        setCurrentGuess("");
        const gameState = loadGameStateFromLocalStorage(level);
        if (gameState !== null) {
            setGuesses(gameState.guesses);
            setCurrentStatus(gameState.status);
            setKeyboardState(
                getKeyboardState(gameState.guesses, gameState.solution)
            );
        } else {
            setGuesses([]);
            setKeyboardState({} as LetterState);
            setCurrentStatus("NOT_DONE");
        }
    };

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    useEffect(() => {
        window.addEventListener("resize", () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        });
    }, []);

    return (
        <div className="container mx-auto flex justify-center main">
            <div className="w-full flex flex-col justify-between">
                <Header onClick={onSelectLevel} currentLevel={currentLevel} />
                <div className="mt-2">
                    <Grid
                        guesses={guesses}
                        currentGuess={currentGuess}
                        currentSolution={currentSolution}
                        animate={animate}
                    />
                </div>
                <div className="mx-1">
                    {currentStatus === "NOT_DONE" ? (
                        <Keyboard
                            onKey={onKey}
                            onDelete={onDelete}
                            onEnter={onEnter}
                            keyboardState={keyboardState}
                        />
                    ) : (
                        <InfoBar
                            level={currentLevel}
                            status={currentStatus}
                            guesses={guesses}
                            solution={currentSolution}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default App;
