import { Level } from "../types/level";

export const generateEmojiGrid = (
    guesses: string[],
    solution: string,
    level: Level
): string => {
    const splitSolution = solution.split("");
    const resultArray = guesses.map((guess) => {
        const splitGuess = guess.split("");
        const emojiList = splitGuess.map((letter, i) => {
            if (letter === splitSolution[i]) {
                return "🟩";
            } else if (splitSolution.indexOf(letter) === -1) {
                return "⬜️";
            } else {
                return "🟨";
            }
        });
        return emojiList;
    });
    const levelName = level === "BEGINNER" ? "초급" : "중급";

    const resultString = resultArray.map((guess) => guess.join("")).join("\n");
    const dateString = new Date().toLocaleDateString();
    return (
        "카카두들 " + levelName + " (" + dateString + ") :\n\n" + resultString
    );
};
