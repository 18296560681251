import { LetterState } from "../types/keyboard";

export const getKeyboardState = (guesses: string[], correctWord: string) => {
    let newLetterState: LetterState = {} as LetterState;

    guesses.forEach((guess) => {
        const split = guess.split("") as (keyof LetterState)[];

        split.forEach((letter: keyof LetterState, i: number) => {
            if (!correctWord.includes(letter)) {
                return (newLetterState[letter] = "absent");
            }
            if (letter === correctWord[i]) {
                return (newLetterState[letter] = "correct");
            }
            if (newLetterState[letter] !== "correct") {
                return (newLetterState[letter] = "present");
            }
        });
    });
    return newLetterState;
};

export const getCellState = (
    guess: string,
    pos: number,
    correctWord: string
) => {
    const letter = guess.split("")[pos];
    if (!correctWord.includes(letter)) {
        return "absent";
    }
    if (letter === correctWord[pos]) {
        return "correct";
    } else {
        return "present";
    }
};
