import { Level } from "../../types/level";

type SelectProps = {
    currentLevel: Level;
    onClick: (value: Level) => void;
};

export const Select = ({ currentLevel, onClick }: SelectProps) => {
    return (
        <div className="w-20 lg:w-24 flex justify-end">
            <button
                className={`h-8 w-10 lg:w-12 lg:h-10 rounded flex justify-center items-center mr-2 ${
                    currentLevel === "BEGINNER" ? "bg-blue-600" : "border"
                }`}
                onClick={() => onClick("BEGINNER")}
            >
                <span
                    className={`text-sm lg:text-base font-bold ${
                        currentLevel === "BEGINNER"
                            ? "text-white"
                            : "text-gray-800"
                    }`}
                >
                    초급
                </span>
            </button>
            <button
                className={`h-8 w-10 lg:w-12 lg:h-10 rounded flex justify-center items-center ${
                    currentLevel === "ADVANCED" ? "bg-blue-600" : "border"
                }`}
                onClick={() => onClick("ADVANCED")}
            >
                <span
                    className={`text-sm lg:text-base font-bold ${
                        currentLevel === "ADVANCED"
                            ? "text-white"
                            : "text-gray-800"
                    }`}
                >
                    중급
                </span>
            </button>
        </div>
    );
};
