import { toast } from "react-toastify";
import { FiShare2 } from "react-icons/fi";
import { copiedToClipboard, share } from "../../data/texts";
import { validGuessesAdvanced } from "../../data/validGuessesAdvanced";
import { validGuessesBeginner } from "../../data/validGuessesBeginner";
import { generateEmojiGrid } from "../../helper/getShareContent";
import { Level } from "../../types/level";
import { Translation } from "../grid/Translation";
import { modalBaseStyles } from "../../styles/modalStyles";

type InfoBarProps = {
    status: string;
    guesses: string[];
    solution: string;
    level: Level;
};

export const InfoBar = ({ status, guesses, solution, level }: InfoBarProps) => {
    const onShare = () => {
        toast.success(copiedToClipboard, {
            ...modalBaseStyles,
            className: "text-center",
        });
        const emojigrid = generateEmojiGrid(guesses, solution, level);
        navigator.clipboard.writeText(emojigrid);
    };

    const solutionObj = [...validGuessesBeginner, ...validGuessesAdvanced].find(
        (el) => el.german === solution
    );

    return (
        <div className="flex justify-center items-center h-44 lg:h-48 lg:mb-2">
            <div className="">
                {status === "LOST" && (
                    <div className="flex mb-6 lg:mb-8 justify-center">
                        {solution.split("").map((el, i) => (
                            <div
                                key={i}
                                className="w-10 h-10 mx-0.5 rounded-xl flex justify-center items-center bg-green-500"
                            >
                                <span className="text-white font-bold">
                                    {el}
                                </span>
                            </div>
                        ))}
                        <Translation text={solutionObj} />
                    </div>
                )}

                <button
                    onClick={onShare}
                    className="px-5 py-3 bg-blue-400 flex items-center rounded mx-auto hover:bg-blue-500"
                >
                    <p className="text-lg lg:text-2xl text-white font-bold mr-2">
                        {share}
                    </p>
                    <FiShare2 className="text-lg lg:text-2xl text-white" />
                </button>
            </div>
        </div>
    );
};
