import { FiInfo, FiMail, FiSquare, FiYoutube } from "react-icons/fi";
import {
    infoDescription,
    infoDifficulty,
    infoGray,
    infoGreen,
    infoTitle,
    infoTranslation,
    infoYellow,
} from "../../data/texts";
import { getBackgroundColor } from "../../helper/getBackgroundColor";

export const InfoModal = () => {
    return (
        <div className="p-2">
            <p className="text-lg font-bold mb-4 text-center mt-2">
                {infoTitle}
            </p>
            <p className="text-sm mb-5 mt-2 px-1">{infoDescription}</p>
            <div className="flex items-center mb-2">
                <div
                    className={`${getBackgroundColor(
                        "absent"
                    )} w-10 h-10 rounded-xl flex justify-center items-center text-white`}
                >
                    <span className="text-xl font-bold">A</span>
                </div>
                <p className="w-3/4 text-sm ml-3">{infoGray}</p>
            </div>
            <div className="flex items-center mb-2">
                <div
                    className={`${getBackgroundColor(
                        "present"
                    )} w-10 h-10 rounded-xl flex justify-center items-center text-white`}
                >
                    <span className="text-xl font-bold">B</span>
                </div>
                <p className="w-3/4 text-sm ml-3">{infoYellow}</p>
            </div>
            <div className="flex items-center mb-4">
                <div
                    className={`${getBackgroundColor(
                        "correct"
                    )} w-10 h-10 rounded-xl flex justify-center items-center text-white`}
                >
                    <span className="text-xl font-bold">C</span>
                </div>
                <p className="w-3/4 text-sm ml-3">{infoGreen}</p>
            </div>
            <div className="flex items-center mb-2">
                <FiInfo className="text-2xl text-blue-400 mx-2 mt-2" />

                <p className="w-3/4 text-sm ml-3 mt-2">{infoTranslation}</p>
            </div>
            <div className="flex items-center mb-2">
                <div className="w-6 h-6 rounded bg-blue-500 mx-2 mt-2 flex justify-center items-center text-sm text-white">
                    중
                </div>

                <p className="w-3/4 text-sm ml-3 mt-2">{infoDifficulty}</p>
            </div>
            <div className="border-b mb-4 mt-8"></div>
            <div className="flex items-center justify-around py-3 mb-4">
                <a
                    href={`https://www.youtube.com/channel/UCKv-RyDz5AKD8JZ4lDd0cpA`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ml-3 w-8 flex items-center justify-center text-center"
                >
                    <FiYoutube className="text-3xl text-gray-500" />
                </a>
                <a
                    href="mailto:info@koreankakadu.de"
                    className="ml-3 w-8 flex items-center justify-center text-center"
                >
                    <FiMail className="text-3xl text-gray-500" />
                </a>
            </div>
            <div className="flex justify-center">
                <a
                    href={`https://www.nytimes.com/games/wordle/index.html`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-center text-gray-400 text-sm hover:underline"
                >
                    오리지널 Wordle
                </a>
            </div>
        </div>
    );
};
