export const endCongratulations = "👏  축하해요!  👏";
export const endSorry = "😩  화이팅!  😩";
export const infoTitle = "매일 한 단어씩 맞혀보세요.";
export const infoDescription = `여섯 번 시도해서 단어를 맞춰보세요. 각각의 추측은 유효한 5글자 단어여야 합니다. 한줄씩 단어를 입력 후 "입력" 버튼을 눌러 제출하세요. 제출한 단어가 정답단어와 일치하는지 타일의 색을 통해 알 수 있습니다.`;
export const infoTranslation = "각 단어의 한국어 의미를 확인 할 수 있습니다.";
export const infoDifficulty = `난이도(초, 중)를 선택할 수 있습니다.`;
export const infoGray = "알파벳이 정답단어에 포함되어 있지않습니다.";
export const infoYellow = "알파벳이 다른 위치에 존재합니다.";
export const infoGreen = "알파벳의 위치가 맞습니다.";
export const copiedToClipboard = "클립보드에 복사됨.";
export const share = "보내기";
export const notInList = "단어가 단어목록에 없습니다. 다시 시도해 보세요.";
