import { getBackgroundColor } from "../../helper/getBackgroundColor";
import { KeyStatus } from "../../types/keyboard";
import { useSpring, animated } from "react-spring";

type CellProps = {
    value?: string;
    status?: KeyStatus;
    n?: number;
    animate?: boolean;
};

export const Cell = ({ value, status, n = 0, animate = false }: CellProps) => {
    const config = { mass: 1, tension: 280, friction: 70 };

    const flipOut = useSpring({
        to: { opacity: 0 },
        from: { opacity: 1 },
        delay: n * 350,
        config: config,
    });
    const ripple = useSpring({
        from: { width: 0, height: 0, color: "#374151" },
        to: { width: 70, height: 70, color: "white" },
        delay: n * 350,
        config: config,
    });
    if (!value)
        return (
            <div
                className={`w-12 h-12 lg:w-14 lg:h-14 mx-0.5 rounded-xl border-2 flex justify-center items-center`}
            ></div>
        );

    if (!status)
        return (
            <div className="w-12 h-12 lg:w-14 lg:h-14 mx-0.5 rounded-xl border-2 border-gray-500 flex justify-center items-center">
                <span className="text-xl lg:text-2xl font-bold text-gray-800">
                    {value}
                </span>
            </div>
        );
    if (!animate)
        return (
            <div
                className={`w-12 h-12 lg:w-14 lg:h-14 mx-0.5 rounded-xl flex justify-center items-center ${getBackgroundColor(
                    status
                )}`}
            >
                <span
                    className={`text-xl lg:text-2xl font-bold ${"text-white"}`}
                >
                    {value}
                </span>
            </div>
        );

    return (
        <div className="relative w-12 h-12 lg:w-14 lg:h-14 mx-0.5 rounded-xl overflow-hidden flex justify-center items-center">
            <animated.div
                style={flipOut}
                className="w-12 h-12 lg:w-14 lg:h-14 rounded-xl border-2 border-gray-500 flex justify-center items-center absolute"
            >
                <span className="text-xl lg:text-2xl font-bold text-gray-800">
                    {value}
                </span>
            </animated.div>
            <animated.div
                style={ripple}
                className={`${getBackgroundColor(
                    status
                )} absolute rounded-full flex justify-center items-center text-white`}
            >
                <span className="text-xl lg:text-2xl font-bold ">{value}</span>
            </animated.div>
        </div>
    );
};
