import { KeyStatus } from "../types/keyboard";

export const getBackgroundColor = (status: KeyStatus | undefined) => {
    switch (status) {
        case "absent":
            return "bg-gray-400";
        case "present":
            return "bg-yellow-500";
        case "correct":
            return "bg-green-500";
        default:
            return "bg-gray-200";
    }
};
