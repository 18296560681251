import { ValidGuess } from "../types/guess";

export const validGuessesBeginner: ValidGuess[] = [
    { german: "LADEN", korean: "가게" },
    { german: "FIRMA", korean: "회사" },
    { german: "JETZT", korean: "지금, 현재, 이제" },
    { german: "BITTE", korean: "부탁" },
    { german: "MAGEN", korean: "위, 배" },
    { german: "REISE", korean: "여행" },
    { german: "SOGAR", korean: "-도, 조차도" },
    { german: "LESEN", korean: "읽다" },
    { german: "IMMER", korean: "항상" },
    { german: "RATEN", korean: "추천하다, 맞춰보다" },
    { german: "BADEN", korean: "목욕을 하다, 수영하하" },
    { german: "MILCH", korean: "우유" },
    { german: "AKTIV", korean: "활동적인, 활발한" },
    { german: "SUPPE", korean: "수프, 국(물)" },
    { german: "HEUTE", korean: "오늘" },
    { german: "AMPEL", korean: "신호등" },
    { german: "LAMPE", korean: "등불, 등, 램프" },
    { german: "STÜCK", korean: "조각, 소량" },
    { german: "TEUER", korean: "비싼" },
    { german: "KLEID", korean: "여성복, 원피스" },
    { german: "SUPER", korean: "멋진, 최고의, 좋은" },
    { german: "BERUF", korean: "직업" },
    { german: "SCHUH", korean: "신발, 구두" },
    { german: "KIOSK", korean: "키오스크, 매점" },
    { german: "LINKS", korean: "왼쪽에" },
    { german: "WAGEN", korean: "자동차" },
    { german: "SPORT", korean: "운동" },
    { german: "PARTY", korean: "파티" },
    { german: "ESSEN", korean: "식사" },
    { german: "TITEL", korean: "제목, 타이틀" },
    { german: "KRANK", korean: "앓는, 병든" },
    { german: "KÜCHE", korean: "부엌, 주방" },
    { german: "WOLKE", korean: "구름" },
    { german: "RUFEN", korean: "외치다, 부르다" },
    { german: "DURCH", korean: "통과하여, 지나서" },
    { german: "NICHT", korean: "...아니다, ...않다" },
    { german: "TASSE", korean: "잔, 커피 잔" },
    { german: "GENAU", korean: "딱 들어맞는, 정확한" },
    { german: "ABEND", korean: "저녁" },
    { german: "SALAT", korean: "샐러드" },
    { german: "ETWAS", korean: "어떤 것, 그 무엇" },
    { german: "BAUCH", korean: "배" },
    { german: "BLUSE", korean: "블라우스" },
    { german: "HAARE", korean: "머리털, 털" },
    { german: "WOCHE", korean: "주" },
    { german: "GABEL", korean: "포크" },
    { german: "JACKE", korean: "재킷" },
    { german: "MARKT", korean: "시장" },
    { german: "BLOND", korean: "금발의" },
    { german: "FLUSS", korean: "강" },
    { german: "NOTIZ", korean: "메모" },
    { german: "SÜDEN", korean: "남쪽" },
    { german: "BLUME", korean: "꽃" },
    { german: "NUDEL", korean: "면" },
    { german: "MENGE", korean: "다수, 다량, 많음" },
    { german: "PLATZ", korean: "좌석, 자리" },
    { german: "JUNGE", korean: "소년" },
    { german: "TISCH", korean: "테이블, 식탁" },
    { german: "DATUM", korean: "날짜" },
    { german: "STARK", korean: "강한, 힘센" },
    { german: "SACHE", korean: "사물, 물건" },
    { german: "BREIT", korean: "넓은" },
    { german: "EILIG", korean: "서둘러야 할, 긴급한" },
    { german: "GLÜCK", korean: "행운, 운" },
    { german: "MUSIK", korean: "음악" },
    { german: "REICH", korean: "돈 많은, 부자인" },
    { german: "HOLEN", korean: "가져오다, 꺼내다, 사오다" },
    { german: "VOGEL", korean: "새" },
    { german: "WOHER", korean: "어디서, 어디로부터" },
    { german: "ANZUG", korean: "신사복, 양복" },
    { german: "MITTE", korean: "중앙, 중간, 한가운데" },
    { german: "THEMA", korean: "주제" },
    { german: "RUHIG", korean: "조용한, 말없는" },
    { german: "UNTEN", korean: "아래에" },
    { german: "SAGEN", korean: "말하다" },
    { german: "SEITE", korean: "페이지, 쪽, 면" },
    { german: "DABEI", korean: "가지고 있다, 그 때에" },
    { german: "HANDY", korean: "핸드폰, 휴대폰" },
    { german: "LICHT", korean: "빛, 밝기" },
    { german: "BOHNE", korean: "콩" },
    { german: "MÖBEL", korean: "가구" },
    { german: "KUNDE", korean: "고객, 손님" },
    { german: "LEBEN", korean: "생활" },
    { german: "BLATT", korean: "페이지, 쪽, 잎, 꽃잎" },
    { german: "SEIFE", korean: "비누" },
    { german: "ANGST", korean: "불안, 걱정" },
    { german: "BAUEN", korean: "세우다, 건축하다" },
    { german: "KRIMI", korean: "탐정 소설" },
    { german: "REIHE", korean: "열, 줄" },
    { german: "BEIDE", korean: "둘다" },
    { german: "STADT", korean: "도시" },
    { german: "GEBEN", korean: "주다, 내주다" },
    { german: "HALLE", korean: "공회당, 강당, 홀" },
    { german: "OFFEN", korean: "열린, 닫히지 않은" },
    { german: "PAUSE", korean: "휴식, 쉬는 시간" },
    { german: "LEUTE", korean: "사람들" },
    { german: "ENDEN", korean: "끝내다" },
    { german: "JEANS", korean: "청바지" },
    { german: "BRIEF", korean: "편지" },
    { german: "HOBBY", korean: "취미" },
    { german: "FEUER", korean: "불" },
    { german: "LANGE", korean: "오래, 오랫동안" },
    { german: "TREND", korean: "유행" },
    { german: "HOTEL", korean: "호텔" },
    { german: "STUHL", korean: "의자" },
    { german: "KASSE", korean: "계산대" },
    { german: "GEGEN", korean: "...을 향하여, 쪽으로" },
    { german: "WEICH", korean: "부드러운" },
    { german: "TORTE", korean: "케이크, 파이" },
    { german: "STIFT", korean: "펜" },
    { german: "INSEL", korean: "섬" },
    { german: "LOKAL", korean: "식당" },
    { german: "LEISE", korean: "낮은 목소리의" },
    { german: "NATUR", korean: "자연" },
    { german: "MÜTZE", korean: "모자" },
    { german: "DURST", korean: "목마름" },
    { german: "GERÄT", korean: "도구, 기구, 기기" },
    { german: "ALTER", korean: "나이" },
    { german: "HILFE", korean: "도움" },
    { german: "OSTEN", korean: "동쪽" },
    { german: "MOTOR", korean: "모터, 엔진" },
    { german: "GENUG", korean: "충분히" },
    { german: "APFEL", korean: "사과" },
    { german: "WENIG", korean: "약간의, 적은" },
    { german: "WIESO", korean: "왜" },
    { german: "BLICK", korean: "광경, 일견" },
    { german: "HABEN", korean: "가지(고 있)다" },
    { german: "MALEN", korean: "그리다" },
    { german: "WEGEN", korean: "...때문에" },
    { german: "HALLO", korean: "여보세요, 안녕" },
    { german: "WARUM", korean: "왜, 무엇 때문에" },
    { german: "FRAGE", korean: "질문" },
    { german: "TOTAL", korean: "완전한, 전체의" },
    { german: "STOCK", korean: "층" },
    { german: "PREIS", korean: "값, 가격" },
    { german: "VATER", korean: "아버지" },
    { german: "TAFEL", korean: "판, 칠판" },
    { german: "WURST", korean: "소시지" },
    { german: "SPASS", korean: "재미, 장난" },
    { german: "ANRUF", korean: "전화, 부름" },
    { german: "LEGEN", korean: "두다, 놓다" },
    { german: "REDEN", korean: "말하다, 이야기하다" },
    { german: "NEBEN", korean: "의 옆에" },
    { german: "MONAT", korean: "달, 월" },
    { german: "LÜGEN", korean: "거짓말하다" },
    { german: "FARBE", korean: "색" },
    { german: "SCHÖN", korean: "아름다운, 멋진" },
    { german: "KATZE", korean: "고양이" },
    { german: "REGEN", korean: "비" },
    { german: "MATHE", korean: "수학" },
    { german: "RECHT", korean: "옳은, 올바른, 틀림없는" },
    { german: "START", korean: "출발, 스타트" },
    { german: "PFERD", korean: "말" },
    { german: "KUNST", korean: "미술, 예술" },
    { german: "KONTO", korean: "계좌, 계정" },
    { german: "MODEL", korean: "모델" },
    { german: "MIETE", korean: "임대" },
    { german: "CREME", korean: "크림" },
    { german: "ÄRZTE", korean: "의사 (복수형)" },
    { german: "FEIER", korean: "파티" },
    { german: "PAKET", korean: "소포" },
    { german: "SONNE", korean: "태양, 해" },
    { german: "HÖREN", korean: "듣다" },
    { german: "KLEIN", korean: "작은" },
    { german: "SAUER", korean: "신(맛 나는)" },
    { german: "SPIEL", korean: "게임, 경기, 놀이" },
    { german: "APRIL", korean: "4월" },
    { german: "SECHS", korean: "육, 여섯" },
    { german: "UNTER", korean: "...의 아래에" },
    { german: "KARTE", korean: "카드" },
    { german: "BEINE", korean: "다리 (복수형)" },
    { german: "PIZZA", korean: "피자" },
    { german: "KINOS", korean: "영화관 (복수형)" },
    { german: "SEHEN", korean: "보다" },
    { german: "SCHON", korean: "이미, 벌써" },
    { german: "GEHEN", korean: "걷다, 걸어가다" },
    { german: "DECKE", korean: "이불" },
    { german: "KETTE", korean: "목걸이, 줄" },
    { german: "BRAUN", korean: "갈색" },
    { german: "BIRNE", korean: "배" },
    { german: "NAMEN", korean: "이름 (복수형)" },
    { german: "MEINE", korean: "나의" },
    { german: "AUTOR", korean: "작자" },
    { german: "RADIO", korean: "라디오" },
    { german: "WOHIN", korean: "어디로" },
    { german: "DANKE", korean: "고맙습니다, 감사합니다" },
    { german: "SONST", korean: "그 외에, 그렇지 않으면" },
    { german: "FISCH", korean: "물고기, 생선" },
    { german: "NACHT", korean: "밤" },
    { german: "DATEI", korean: "파일" },
    { german: "MÖGEN", korean: "좋아하다" },
    { german: "TRAUM", korean: "꿈" },
    { german: "FREMD", korean: "외래의, 외국에서 온" },
    { german: "GLEIS", korean: "레일, 철도" },
];
